
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const checked = ref(false);

    const list = [
      {
        image: "media/avatars/300-14.jpg",
        name: "Yeşim Aksoy",
        skills: "Sarayköy, Denizli",
        companyName: "7. Sınıf",
        companySkills: "Atatürk ortaokulu",
        value: "50",
        color: "primary",
      },
      {
        image: "media/avatars/300-2.jpg",
        name: "Buse Can",
        skills: "Yüreğir, Adana",
        companyName: "9. sınıf",
        companySkills: "Atatürk lisesi",
        value: "70",
        color: "danger",
      },
      {
        image: "media/avatars/300-5.jpg",
        name: "Yakup Yavuz",
        skills: "Ereğli, Konya",
        companyName: "12. Sınıf",
        companySkills: "Nuh Mehmet Baldöktü Anadolu Lisesi",
        value: "60",
        color: "success",
      },
      {
        image: "media/avatars/300-20.jpg",
        name: "Demet Keskin",
        skills: "Akçabat, Trabzon",
        companyName: "10. Sınıf",
        companySkills: "Halide Edip Adıvar Lisesi",
        value: "50",
        color: "warning",
      },
      {
        image: "media/avatars/300-23.jpg",
        name: "Ahmet Kurt",
        skills: "Merzifon, Amasya",
        companyName: "11. Sınıf",
        companySkills: "Tofaş Fen Lisesi",
        value: "90",
        color: "info",
      },
    ];

    return {
      list,
      checked,
    };
  },
});
