
import { defineComponent, ref } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "kt-widget-2",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        avatar: "http://www.kastamonugazetesi.com.tr/wp-content/uploads/2017/09/gorsel-hazirlaniyor.jpg",
        name: "Tonguç Akademi",
        description: "Tonguç anladıysa herkes anlar",
      },
      {
        avatar: "http://www.kastamonugazetesi.com.tr/wp-content/uploads/2017/09/gorsel-hazirlaniyor.jpg",
        name: "Gri Koç",
        description: "gridenbeyaza",
      },
      {
        avatar: "http://www.kastamonugazetesi.com.tr/wp-content/uploads/2017/09/gorsel-hazirlaniyor.jpg",
        name: "Kimya Adası",
        description: "",
      },
      {
        avatar: "http://www.kastamonugazetesi.com.tr/wp-content/uploads/2017/09/gorsel-hazirlaniyor.jpg",
        name: "İmt Hoca",
        description: "",
      },
      {
        avatar: "http://www.kastamonugazetesi.com.tr/wp-content/uploads/2017/09/gorsel-hazirlaniyor.jpg",
        name: "Rehber Matematik",
        description: "",
      },
    ]);

    return {
      list,
    };
  },
});
