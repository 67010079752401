
import { defineComponent, ref } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    Dropdown3,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        color: "warning",
        icon: "icons/duotune/abstract/abs027.svg",
        title: "Gri koç yeni paket oluşturdu",
        text: "Onay Bekliyor",
        number: "",
      },
      {
        color: "success",
        icon: "icons/duotune/art/art005.svg",
        title: "Barajı geç paketi başarıyla onaylandı",
        text: "",
        number: "",
      },
      {
        color: "danger",
        icon: "icons/duotune/communication/com012.svg",
        title: "Kayıtlı kullanıcı sayısı 100'ü geçti",
        text: "",
        number: "",
      },
      {
        color: "info",
        icon: "icons/duotune/communication/com012.svg",
        title: "Şenol Hoca Yeni paket oluşturdu",
        text: "Onay Bekliyor",
        number: "",
      },
    ]);

    return {
      list,
    };
  },
});
